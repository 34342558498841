/*-------------------------
   About Us
-------------------------*/
.about-us {
  .section-heading {
    p {
      width: 100%;
    }
  }
}

.contact-form-box {
  background-color: var(--color-white);
  padding: 50px;
  border-radius: 30px;
  @media #{$sm-layout} {
    padding: 40px 30px;
  }
  .title {
    font-size: 40px;
    @media #{$laptop-device} {
      font-size: 36px;
    }
    @media #{$smlg-device} {
      font-size: 32px;
    }
    @media #{$sm-layout} {
      font-size: 28px;
    }
  }
  .form-group {
    .axil-btn {
      &::after {
        width: calc(100% + 50px);
      }
    }
  }
  &.shadow-box {
    box-shadow: 0 20px 48px 0 rgba(153, 161, 170, 0.25);
  }
}

.about-expert {
  text-align: center;
  position: relative;
  .thumbnail {
    position: relative;
    display: inline-block;
    border-radius: 40px;
    box-shadow: 0px 17px 26px 0px rgba(0, 0, 0, 0.2);
    img {
      border-radius: 40px;
    }
    .popup-video {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      .play-btn {
        height: 150px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
        border: none;
        border-radius: 50%;
        margin: 0 auto;
        font-size: 32px;
        color: var(--color-white);
        transition: var(--transition);
        &:hover {
          background-color: var(--color-primary);
        }
        @media #{$md-layout} {
          height: 120px;
          width: 120px;
          font-size: 28px;
        }
        @media #{$sm-layout} {
          height: 80px;
          width: 80px;
          font-size: 24px;
        }
      }
    }
  }
  .frame-shape {
    @media #{$smlg-device} {
      display: none;
    }
    .shape {
      position: absolute;
      &.shape-1 {
        left: -10px;
        bottom: 75px;
      }
      &.shape-2 {
        bottom: 190px;
        right: -30px;
      }
    }
  }
}

.about-quality {
  margin-bottom: 60px;
  .sl-number {
    text-align: center;
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    background: linear-gradient(90deg, #565677 0%, rgba(39, 39, 46, 0) 70.31%);
    color: var(--color-white);
    margin-bottom: 40px;
    transition: var(--transition);
  }
  .title {
    color: var(--color-white);
  }
  p {
    opacity: 0.5;
    color: var(--color-white);
    transition: var(--transition);
  }
  &:hover,
  &.active {
    .sl-number {
      background-color: var(--color-primary);
    }
    p {
      opacity: 1;
    }
  }
}

.about-team {
  .section-heading {
    p {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .axil-btn {
    width: 240px;
  }
  .thumbnail {
    padding-right: 100px;
  }
}
