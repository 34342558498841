/*-------------------------
    FAQ
-------------------------*/
.faq-area {
  .section-heading {
    .title {
      font-size: 48px;
      margin-bottom: 40px;
      @media #{$large-mobile} {
        font-size: 38px;
      }
    }
    p {
      width: 100%;
    }
  }
}

.faq-accordion {
  .accordion-item {
    background-color: var(--color-white);
    border-radius: 24px;
    border: none;
    margin-bottom: 20px;
    box-shadow: 0 10px 48px -30px rgba(153, 161, 170, 1);
    transition: var(--transition);
    &:last-child {
      margin-bottom: 0;
      .accordion-button.collapsed {
        margin-bottom: 0;
      }
    }
  }

  .accordion-button {
    box-shadow: none;
    font-size: var(--font-body-1);
    color: var(--color-primary);
    font-weight: 500;
    font-family: var(--font-primary);
    padding: 30px 40px 20px;
    position: relative;
    border-radius: 0 !important;
    transition: var(--transition);
    background-color: transparent;
    span {
      margin-right: 5px;
    }

    &.collapsed {
      color: var(--color-dark);
      border-radius: 24px !important;
      padding: 30px 40px;
      background-color: #f8f8f8;
      margin-bottom: -10px;
    }
  }
  .accordion-body {
    padding: 0 40px 40px;
    p {
      color: var(--color-gray-1);
      margin-bottom: 0;
    }
  }
}
