/*-------------------------
   Brand
-------------------------*/
.clients {
  align-items: center;
}
.brand-wrap-area {
  padding: 90px 0;
}
.brand-grid {
  margin: auto;
  max-width: 180px;
  padding: 10px 0;
  @media only screen and (max-width: 768px) {
    padding: 36px 0;
  }
}
