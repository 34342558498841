/*-------------------------
    Forms Styles
-------------------------*/
input,
.form-control {
  border: var(--border-lighter);
  border-radius: 16px;
  background-color: transparent;
  color: var(--color-text-dark);
  padding: 15px 20px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0 20px 48px -14px rgba(153, 161, 170, 0.25);
    border-color: transparent;
  }
  /* -- Placeholder -- */
  &::placeholder {
    color: var(--color-gray-2);
    /* Firefox */
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-gray-2);
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-gray-2);
  }
}
input {
  height: 60px;
}

.form-group {
  margin-bottom: 16px;
  label {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark);
    margin-bottom: 10px;
  }
}

// Input Group

.input-group {
  border-radius: 20px;
  padding: 10px 10px 10px 55px;
  box-shadow: 0 24px 48px -15px rgba(153, 161, 170, 0.25);
  border: none;
  background-color: var(--color-primary);
  color: var(--color-white);
  margin-top: 6px;
  margin-bottom: 6px;
  transition: var(--transition);
  &:hover {
    background-color: var(--color-link);
  }
  @media #{$small-mobile} {
    display: block;
    box-shadow: none;
    height: auto;
    padding: 0;
  }
}

// Form Action Message

.error-msg,
.success-msg {
  margin-top: 25px;
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
}

.error-msg {
  color: #ff0000;
}

.success-msg {
  color: #15c54b;
  margin-bottom: 0;
}
