/* -----------------------
Pagination 
--------------------------*/
.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px -3px -3px -3px;
  padding: 0;
  width: 100%;
  @media #{$large-mobile} {
    margin: -2px;
  }
  li {
    margin: 3px;
    @media #{$large-mobile} {
      margin: 2px;
    }
    a {
      line-height: 42px;
      min-width: 42px;
      text-align: center;
      color: var(--color-dark);
      transition: all 0.5s;
      display: block;
      padding: 0 15px;
      transition: all 0.5s;
      border: var(--border-light);
      border-radius: 4px;
      &:hover {
        background: var(--color-primary);
        color: #ffffff !important;
        border-color: var(--color-primary);
      }
      @media #{$large-mobile} {
        line-height: 35px;
        min-width: 35px;
        padding: 0 10px;
        font-size: 14px;
      }
    }
    &.current {
      a {
        background: var(--color-primary);
        color: #ffffff;
        border-color: var(--color-primary);
      }
    }
    &.disabled {
      opacity: 0.4;
      a {
        &:hover {
          background-color: transparent;
          border: var(--border-light);
          color: var(--color-dark) !important;
        }
      }
    }
  }
}
